/*!******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

@import '~react-table/react-table.css';
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

// Variable overrides
$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-200: #f1f1f1; // modified
$gray-300: #dee2e6;
$gray-400: #ced4da; // modified
$gray-500: #adb5bd;
$gray-600: #868c92;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #191919; // modified

$body-bg: $gray-200;

//$blue:    #007bff;
//$indigo:  #6610f2 !default;
//$purple:  #6f42c1 !default;
//$pink:    #e83e8c !default;
$red:     #e80808;
//$orange:  #fd7e14 !default;
//$yellow:  #ffc107 !default;
$green:   #03A64A;
//$teal:    #20c997 !default;
//$cyan:    #17a2b8 !default;

//$primary: $black;
$secondary: #F5C478;
//$success: $red;
//$info: $gray-500;
//$warning: $yellow !default;
//$danger: $black;
$light: $gray-300;
$dark: $gray-700;

//$form-feedback-invalid-color: $secondary;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.3;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.15;
$h6-font-size: $font-size-base;

$badge-font-size: 85%;
$font-weight-bold: 700;

$headings-font-family: 'Mulish', sans-serif; // for headers
$font-family-sans-serif: 'Mulish', sans-serif; // for normal text
$font-family-sans-serif-bold: 'Mulish', sans-serif; // for bold text

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$grid-gutter-width: 14px;

$nav-link-padding-y: .2rem;

$input-btn-padding-y-sm: 0.12rem;

$badge-font-weight: 400;

$nav-pills-link-active-bg: $green;

@import "~bootstrap/scss/bootstrap";

/*** Global ***/

html {
  overflow-y:scroll;
  font-size: 12px;
}

.bold {
  font-family: $font-family-sans-serif-bold;
}

.text-transparent {
  color: rgba(0,0,0,0);
}

.cursor-pointer {
  cursor: pointer;
}

#topbar {
  background-color: white;
}

.navbar-brand {
  color: black;

  img {
    margin-top: -8px;
  }
}

ul {
  padding-inline-start: 20px;
  margin-bottom: 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
}

#main-panel {
  background-color: $gray-200;
  padding: 1em;
}

.btn {
  font-family: $headings-font-family;
}

.title {
  margin-right: 1em;
}

.navbar {
  padding-left: 8%;
  padding-right: 8%;

  img.logo {
    margin-right: 0.8em;
    width: 54px;
  }

  .nav-menu-item {
    margin-right: 5vw;

    a {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;
      color: $black;

      &:hover {
        color: $green;
      }

      &.active:hover {
        color: darken($green, 10%);
      }

    }
  }

  #login {
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.iframe-modal-footer{
  justify-content: space-between;
}

.iframe-container {
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

/*** Custom icons ***/

.custom-icon {
  height: 16px;
  width: 16px;
  background: no-repeat center;
  background-size: contain;
  transition-delay: 0.07s;
  display: inline-block;
  vertical-align: middle;
}

.custom-icon.big {
  height: 20px;
  width: 20px;
}

button:not(.disabled):hover .custom-icon {
  filter: invert(100%);
}

.custom-icon.example {
  background-image: url('/img/icons/example.svg');
}

/*** Dashboard elements ***/

#dashboard .row + .row{
  margin-top: 14px;
}

.area {
  height: 100%;
  background-color: white;
}

.area .area-header {
  padding: 0.1rem 1rem;
  font-family: $headings-font-family;
  font-size: 1.1em;
}

.area .area-body {
  border-top: 3px solid $gray-200;
  padding: 0.7rem 1rem;
}

.nested-area {
  border: 1px solid gray;
  height: 100%;
  padding: 10px;
  margin: 10px;
  font-size: 9pt;
}

.cluster-area {
  display: flex;
  padding: 30px;
  align-items: flex-end;
  justify-content: center;
}

.refresh-feature {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.refresh-button {
  margin-left: 12px;
  border-width: 0;
}

.no-data-overlay {
  text-align: center;
  vertical-align: middle;
  line-height: 20vh;
}

/*** Near table elements ***/

.above-tasksgrid {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}

.center-panel {
  padding-bottom: 5px;
}

.right-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#modifyColumns {
  font-family: $font-family-sans-serif;
  text-decoration: underline;

  &:hover {
    font-family: $font-family-sans-serif-bold;
  }
}

/*** Tables ***/

.ReactTable {
  font-family: $font-family-sans-serif-bold;
  background-color: white;
}

$fat-table-border: 3px solid $gray-200;
$thin-table-border: 1px solid $gray-200;

// Fat border table

.ReactTable {
  border: $fat-table-border;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-tbody .rt-tr-group,
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: $fat-table-border;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-tfoot .rt-td {
  border-right: $fat-table-border;
}

// Thin border table

.ReactTable.-thin {
  border: $thin-table-border;
}

.ReactTable.-thin .rt-thead.-header,
.ReactTable.-thin .rt-tbody .rt-tr-group,
.ReactTable.-thin .rt-tbody .rt-tr-group:last-child {
  border-bottom: $thin-table-border;
}

.ReactTable.-thin .rt-thead .rt-th,
.ReactTable.-thin .rt-thead .rt-td,
.ReactTable.-thin .rt-tbody .rt-td,
.ReactTable.-thin .rt-tfoot .rt-td {
  border-right: $thin-table-border;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-tfoot {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-tfoot .rt-td {
  text-align: center;
}

.rt-resizable-header-content {
  font-family: $headings-font-family;
  padding: 0.5em 0;

  label {
    font-family: $font-family-sans-serif;
    margin-top: 0.3em;
    margin-bottom: 0;
  }
}

.rt-resizable-header-content:first-letter {
  text-transform: uppercase;
}

.ReactTable .rt-thead .rt-th.row-header,
.ReactTable .rt-thead .rt-td.row-header,
.ReactTable .rt-tbody .rt-td.row-header,
.ReactTable .rt-tfoot .rt-td.row-header {
  font-family: $headings-font-family;
  text-align: left;
  padding-left: 1em;
}

.-filters input::placeholder {
  color: #D3D3D3;
}

.rt-tr.selected,
.rt-tr.selected:hover {
  background-color: lightgray;
}

.ReactTable .rt-resizer {
  width: 14px;
  right: -7px;
}

.droppable-list.dragging-over .draggable-item {
  background: $gray-200;
}

.draggable-item {
  user-select: none;
}

.droppable-list.dragging-over .draggable-item.dragged {
  background: rgba(242, 95, 95, 0.69);
}

.draggable-item.dragged {
  display: table;
}

.order-column,
.visibility-column {
  text-align: center;
}

.vis-thumbnail {
  max-width: 100%;
}

.-header label:not(.text-transparent) input[type="checkbox"] + * {
  font-size: 0.825rem;
  &:before {
    width: 14px;
    height: 14px;
  }
}

// Before filters

$filters-width: 250px;

#tasks-header {
  min-height: 2.8em;
}

#filters-header {
  width: $filters-width - 1px;
  position: absolute;
  margin-left: 0 - $filters-width - 9px;
  background-color: white;
  padding: 0.5em 1.5em;
  height: 4.2rem;
  margin-top: -0.6rem;
  font-family: $font-family-sans-serif;
}

// Filter on left

#table-area {
  margin-left: $filters-width;
}

.filters-panel {
  position: absolute;
  margin-left: 0 - $filters-width - 10px;
  min-width: 0 !important;
  background-color: white;
  padding-bottom: 2em;
  top: 0;
}

.filters-panel .rt-tr {
  width: $filters-width;
  display: block;
}

.filters-panel .rt-tr .rt-th {
  width: 100% !important;
  max-width: inherit !important;
  text-align: left;
  padding: 0 1.5em;
}

.filters-panel .rt-tr .rt-th .filter-header {
  margin-top: 0.8em;
  margin-bottom: 0.6em;
  font-family: $headings-font-family;
  max-width: $filters-width - 70px;
  white-space: normal;
  font-weight: 700;
}

.filters-panel .rt-tr .rt-th input[type=checkbox] {
  margin-right: 0.6em;
}

.under-table {
  margin: 1em 0;
  text-align: center;

  button {
    margin: 2.5rem 2rem;

    .button-text {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.2rem;
    }
  }
}

ul.task-fields-list {
  list-style: none;
  padding-left: 1em;
}

table.task-history thead {
  text-transform: capitalize;
}

table.task-history th,
table.task-history td {
  border: 1px solid #e3e3e3;
  padding: 0.2em 1em;
  text-align: center;
  white-space: nowrap;
}

h2 {
  text-transform: capitalize;
  margin-top: 1.5em;
}

#charts > .row > .col {
  margin: 1.5em 0;
  padding: 0;
}

.chart-box {
  width: 62%;
}

.chart-box-line {
  height: 40vh;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-tooltip {
  background: white;
  opacity: 0.9;
  padding: 9px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.65);
}

#filters-header .remove-filter {
  margin-right: -1em;
}

.submit-time-header .remove-filter {
  margin-top: -1.2em;
}

.modify-time-header .remove-filter {
  margin-top: -0.2em;
}

.remove-filter {
  margin-right: -2.27em;
}

/*** All modals ***/
.modal-content {
  border: none;
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding: 0 1rem 1rem;
  }
}

.task-details-modal {
  max-width: 70vw;
}

.badge.wrap {
  white-space: normal;
}


/*** Datetime picker ***/

@import "~react-datetime/css/react-datetime.css";

.rdtOpen .rdtPicker {
  position: relative;
  margin-left: -1.5em;
}

.ReactTable .rt-thead.-filters {
  .rdt input {
    line-height: normal;
    height: auto;
  }

  .calendar-input-icon {
    margin-top: 0.5em;
    right: 2.5em;
    z-index: 100;
    position: absolute;
    pointer-events: none;
  }
}


.task-history-body {
  display: inline-block;

  button.task-history-refresh {
    float: right;
    margin-left: -2rem;
    margin-top: -2rem;
    padding: 0.2rem 0.25rem;

    &:hover {
      background-color: $gray-200;
    }

    &:active {
      background-color: $gray-400;
    }
    svg {
      height: 1.5em;
      width: 1.5em;
    }
  }
}

.task-fields-list {
  word-break: break-all;
}

/*** Show JSON modal ***/

.pre-scrollable {
  max-height: 70vh;
}

#text-file-icon {
  margin-top: -3px;
}


/*** Popover closing X ***/

.popover-close {
  margin-top: -0.2rem;
}

/*** Task actions ***/

.queue-mover {
  float: left;
}

.task-canceler {
  float: right;
  margin-right: 3em;
}

/*** Notifications ***/

.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
    font-family: $headings-font-family;
    font-size: 1.1em;
  }
  .rrt-text {
    font-family: $font-family-sans-serif-bold;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
    color: white;
  }
  &.rrt-error {
    background-color: $danger;
  }
  &.rrt-success {
    background-color: $success;
  }
  &.rrt-warning {
    background-color: $dark;
  }
  &.rrt-info {
    background-color: $info;
  }
  .close-toastr {
    opacity: 0.6;
  }
}

/*** Form ***/
fieldset {
  border: 1px solid green;
  margin: 1rem;
  padding: 1rem;
}

legend {
  width: auto;
  margin: 0 0 -0.5rem 0;
  padding: 0 1rem;
  font-size: 1.2rem;
}

.field-description {
  color: gray;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.form-group > .field-description {
  float: right;
}

.array-item-toolbox {
  margin-top: 2rem;
}

.form-group.field.field-object .form-group.field {
  background-color: #ededed;
  padding: 6px;
  margin: 8px;
}

/*** EoCoE landing ***/

.landing-jumbotron {
  min-height: 90vh;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./../../assets/img/landing-background-gray.png');
  background-position: center bottom;
  color: #ffffff;
  padding: 18vh 8%;

  .titles {
    width: 30%;
    min-width: 300px;

    h1 {
      font-size: 45px;
      font-weight: 800;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.3;
    }

    p.lid {
      font-size: 18px;
    }

    a {
      font-weight: 700;
    }
  }
}

.concrete-list-title {
  font-size: 36px;
  font-weight: 800;
  padding: 0 3em;
  line-height: 45px;
  max-width: 630px;
}

.concrete-list {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5em;

  > div[class*="col-"] {
    margin-bottom: 3em;
    padding-left: 1em;
    padding-right: 3em;
  }

  .marker {
    color: $secondary;
    font-family: 'rubik', sans-serif;
    font-size: 36px;
    font-weight: 500;
  }
}

/*** Footer ***/
footer {
  #footer-text {
    padding: 2rem 14vw 0;
  }
  .eocoe-logo {
    width: 75px;
  }
  .ec-logo {
    width: 60%;
    min-width: 150px;
  }
  .pcss-logo {
    width: 55px;
    margin-left: 0.5em;
  }
}
